import React from "react";
import "./ShoppingLink.scss";
// import egg_logo from "../../assets/egg_logo_2.jpg";
import ncg_logo from "../../assets/NCG_logo1.png";

const ShoppingLink = ({ item }) => {
  const getLinkAndImageURLs = (map) => {
    const propertyName = "BGG IDs";
    const linkNumbersCSV = map[propertyName];

    //shopping link URLs
    const numberLinkDictionary = {
      //EAGLE-GRYPHON GAMES
      // 4098: "https://www.eaglegames.net/Age-of-Steam-Deluxe-Edition-p/102380.htm",
      // 362228:
      //   "https://www.eaglegames.net/Age-of-Steam-Deluxe-Expansion-Volume-I-p/102391.htm",
      // 362227:
      //   "https://www.eaglegames.net/Age-of-Steam-Deluxe-Expansion-Volume-II-p/102392.htm",
      // 378715:
      //   "https://www.eaglegames.net/Age-of-Steam-Deluxe-Expansion-Volume-III-p/102393.htm",
      // 396948:
      //   "https://www.eaglegames.net/Age-of-Steam-Deluxe-Expansion-Volume-IV-p/102432.htm",

      //NEON-COMET GAMES
      380094: "https://neoncometgames.com/product/bahia-1808/",
      380092: "https://neoncometgames.com/product/2023-map-set/",
      380093: "https://neoncometgames.com/product/2023-map-set/",
      380091: "https://neoncometgames.com/product/2023-map-set/",
      404915: "https://neoncometgames.com/product/steampunk/",

      //ALBAN VIARD GAMES
    };

    //image URLs
    const imageNumberDictionary = {
      //EAGLE-GRYPHON GAMES
      // 4098: egg_logo,
      // 362228: egg_logo,
      // 362227: egg_logo,
      // 378715: egg_logo,
      // 396948: egg_logo,
      //NEON-COMET GAMES
      380094: ncg_logo,
      380092: ncg_logo,
      380093: ncg_logo,
      380091: ncg_logo,
      404915: ncg_logo,
    };

    // Split the CSV string into an array of numbers
    const linkNumbers = linkNumbersCSV
      ? linkNumbersCSV.split(",").map(Number)
      : [];

    // Find the first matching link in the dictionaries
    const matchingLink = linkNumbers.find(
      (number) => numberLinkDictionary[number]
    );

    const linkURL =
      matchingLink !== undefined ? numberLinkDictionary[matchingLink] : "";
    const imageURL =
      matchingLink !== undefined ? imageNumberDictionary[matchingLink] : "";

    return { linkURL, imageURL };
  };

  // Call the getLinkAndImageURLs function
  const { linkURL, imageURL } = getLinkAndImageURLs(item);

  console.log(imageURL);

  if (linkURL !== "") {
    return (
      <div className="shopping-link">
        <a href={linkURL} target="_blank" rel="noopener noreferrer">
          <img src={imageURL} alt="Shopping Item" />
          <h4>Purchase Here</h4>
        </a>
      </div>
    );
  } else {
    return null;
  }
};

export default ShoppingLink;
