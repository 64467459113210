import axios from "axios";
import xmljs from "xml-js";
import { BGG_API_URL } from "../constants/constants";

const api = axios.create({
  baseURL: BGG_API_URL,
  responseType: "text",
});

const xmlResponseInterceptor = (response) => {
  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

api.interceptors.response.use(xmlResponseInterceptor, (error) =>
  Promise.reject(error)
);

export const fetchUserCollection = async (username) => {
  try {
    let retryCount = 0;

    while (true) {
      const response = await api.get(`/collection?username=${username}&own=1`, {
        headers: {
          "Content-Type": "application/xml",
        },
      });

      if (response && response.status === 200) {
        const jsonData = xmljs.xml2json(response.data, {
          compact: true,
          spaces: 2,
        });

        return JSON.parse(jsonData);
      } else if (response && response.status === 202) {
        // Retry logic
        const waitTimeInSeconds = 5;
        console.log(
          `Received status 202. Retrying in ${waitTimeInSeconds} seconds...`
        );
        await new Promise((resolve) =>
          setTimeout(resolve, waitTimeInSeconds * 1000)
        );
        retryCount++;

        if (retryCount >= 3) {
          throw new Error("Exceeded maximum number of retries");
        }
      } else {
        throw new Error(
          `Failed to fetch data. Status: ${
            response ? response.status : "undefined"
          }`
        );
      }
    }
  } catch (error) {
    console.error("Failed to fetch data", error);
    throw new Error("Failed to fetch data");
  }
};
