import "./App.scss";
import Header from "./components/Header/Header";
import Filters from "./components/Filters/Filters";
import MapTree from "./components/MapTree/MapTree";
import Footer from "./components/Footer/Footer";
import UserSearch from "./components/SearchUser/SearchUser";
import { fetchAndCacheImages } from "./services/imageService";
import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    fetchAndCacheImages();

    return () => {};
  }, []);

  return (
    <div className="App">
      <div>
        <Header />
      </div>
      <div className="App-content">
        <div className="App-labels">
          <UserSearch />
          <Filters />
          <MapTree />
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
