import React, { useEffect, useState } from "react";
import "./Map.scss";
import MapImage from "../MapImage/MapImage";
import { MAP_IMAGE_BUCKET_URL } from "../../constants/constants";
import {
  calculateBest,
  calculatePlays,
  getBggLink,
} from "../../utils/helper-functions";
import { useDispatch, useSelector } from "react-redux";
import { setImagesLoading } from "../../actions/actions";
import ShoppingLink from "../ShoppingLink/ShoppingLink";

const Map = ({ item, className }) => {
  const [cachedImageURL, setCachedImageURL] = useState(null);
  const dispatch = useDispatch();
  const imagesLoading = useSelector((state) => state.imagesLoading);

  useEffect(() => {
    async function loadImage() {
      try {
        const cache = await caches.open("image-cache");
        const cachedResponse = await cache.match(
          new Request(`${MAP_IMAGE_BUCKET_URL}/images/${item.id}.PNG`)
        );

        if (cachedResponse) {
          const blob = await cachedResponse.blob();
          const newImageURL = URL.createObjectURL(blob);
          setCachedImageURL(newImageURL);
          dispatch(setImagesLoading(false));
        } else {
          dispatch(setImagesLoading(false));
        }
      } catch (error) {
        console.error(
          `Error loading cached image: ${MAP_IMAGE_BUCKET_URL}/${item.id}.PNG`,
          error
        );
        dispatch(setImagesLoading(false));
      }
    }

    if (!cachedImageURL && !imagesLoading) {
      dispatch(setImagesLoading(true));
      loadImage();
    }
  }, [cachedImageURL, item.id, imagesLoading, dispatch]);

  return (
    <div className={`map-container ${className}`} key={item.id}>
      <div className="item-header">
        <h2>{item.Map}</h2>
      </div>
      <div className="item-details-container">
        <div className="map-image-container">
          {cachedImageURL && !imagesLoading && (
            <MapImage imageURL={cachedImageURL} />
          )}
        </div>

        <div className="text-container">
          <div className="text-pair">
            <h4 className="static-text">Designer:</h4>
            <h4 className="dynamic-text">{item.Designer}</h4>
          </div>
          <div className="text-pair">
            <h4 className="static-text">Best At:</h4>
            <h4 className="dynamic-text">
              {calculateBest(item).length >= 1
                ? calculateBest(item)
                : "Unknown"}
            </h4>
          </div>

          <div className="text-pair">
            <h4 className="static-text">Plays:</h4>
            <h4 className="dynamic-text">
              {calculatePlays(item).length >= 1
                ? calculatePlays(item)
                : "Unknown"}
            </h4>
          </div>

          <div className="text-pair">
            <h4 className="static-text">BGG Link(s):</h4>
            <h4
              className="dynamic-text-link"
              dangerouslySetInnerHTML={{ __html: getBggLink(item) }}
            ></h4>
          </div>
        </div>
      </div>
      <ShoppingLink item={item} />
    </div>
  );
};

export default Map;
