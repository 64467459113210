import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./RandomModal.scss";
import Map from "../Map/Map";
import {
  calculateBest,
  calculatePlays,
  getBggLink,
} from "../../utils/helper-functions";
import { useSelector } from "react-redux";
import { SEARCH_PLACEHOLDER } from "../../constants/constants";

const RandomMapModal = ({ isOpen, closeModal }) => {
  const [randomId, setRandomId] = useState(null);

  //STATE VARIABLES
  const data = useSelector((state) => state.data.raw);
  const filterOptions = useSelector((state) => state.data.filterOptions);
  const filterType = useSelector((state) => state.data.filterType);
  const searchTerm = useSelector((state) => state.data.searchTerm);
  const sortOption = useSelector((state) => state.data.sortOption);
  const userCollectionActive = useSelector(
    (state) => state.data.userCollectionActive
  );
  const userCollectionData = useSelector(
    (state) => state.data.userCollectionData
  );

  //Method to see if a given map meets filter criteria
  const shouldIncludeItem = (item) => {
    let searchCheck;
    if (searchTerm.toLowerCase() !== { SEARCH_PLACEHOLDER }) {
      searchCheck = item.Map.toLowerCase().includes(searchTerm.toLowerCase());
    }

    if (filterOptions.includes("all")) {
      return true && searchCheck;
    }

    if (filterType === "plays") {
      const shouldInclude = filterOptions.some(
        (option) => item[`${option}`] >= 3
      );
      return shouldInclude && searchCheck;
    } else if (filterType === "best") {
      const bestAt = calculateBest(item);
      const shouldInclude = filterOptions.some((option) =>
        bestAt.includes(String(option.slice(1)))
      );
      return shouldInclude && searchCheck;
    }
  };

  // Method that retrieves the filtered data
  const getFilteredData = () => {
    let filteredData;
    if (userCollectionActive && userCollectionData) {
      filteredData = filterUserData();
    } else if (data) {
      filteredData = data.filter(shouldIncludeItem);
    } else {
      filteredData = [];
    }
    return filteredData.filter(shouldIncludeItem);
  };

  const filterUserData = () => {
    // Check if userCollectionData and userCollectionData.items exist
    if (!userCollectionData || !userCollectionData.items) {
      return [];
    }

    // Ensure that items is an array
    const userCollectionItems = Array.isArray(userCollectionData.items.item)
      ? userCollectionData.items.item
      : userCollectionData.items.item
      ? [userCollectionData.items.item] // wrap in an array if it's a single item
      : [];

    // Extracting object IDs from userCollectionData
    const userCollectionIDs = userCollectionItems.map((item) => {
      return item._attributes && item._attributes.objectid
        ? String(item._attributes.objectid)
        : null;
    });

    // Filter out null values (in case objectid is missing in some items)
    const validUserCollectionIDs = userCollectionIDs.filter(
      (id) => id !== null
    );

    // Then match the object IDs with data.id
    return data.filter((item) => {
      const bggIDs = item["BGG IDs"];

      // Check if bggIDs is a string with comma-separated values
      if (typeof bggIDs === "string") {
        const bggIDsArray = bggIDs.split(", ").map((id) => id.trim());

        // Check if any value in bggIDsArray is included in validUserCollectionIDs
        return bggIDsArray.some((bggID) =>
          validUserCollectionIDs.includes(bggID)
        );
      }

      // If bggIDs is not a string, check if it's included in validUserCollectionIDs
      return validUserCollectionIDs.includes(String(bggIDs));
    });
  };

  // Method that retrieves the sorted data
  const getSortedData = () => {
    const filteredData = getFilteredData();

    switch (sortOption) {
      case "alphabetical":
        return [...filteredData].sort((a, b) => a.Map.localeCompare(b.Map));
      default:
        return filteredData;
    }
  };

  // Helper function to calculate number of filtered maps
  const getFilteredLength = () => {
    return getFilteredData().length;
  };

  useEffect(() => {
    // Clear randomId when modal is closed
    if (!isOpen) {
      setRandomId(null);
    } else {
      setRandomId(generateRandomId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setRandomId(generateRandomId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const reRoll = () => {
    setRandomId(generateRandomId());
  };

  const generateRandomId = () => {
    if (!getFilteredData() || getFilteredLength() === 0) {
      return null;
    }
    const sortedData = getSortedData();
    const randomIndex = Math.floor(Math.random() * sortedData.length);
    return sortedData[randomIndex].id;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div className="modal-body">
        {/* Top Row: Header */}
        <div className="random-item-header">
          <h1>Random Map</h1>
        </div>

        {/* Middle Row: Map Component */}
        <div>
          {randomId && (
            <Map
              key={randomId}
              item={data.find((item) => item.id === randomId)}
              calculatePlays={calculatePlays}
              calculateBest={calculateBest}
              getBggLink={getBggLink}
              className="App-containers-random"
            />
          )}
        </div>

        {/* Bottom Row: Buttons */}
        <div className="modal-buttons" style={{ textAlign: "center" }}>
          <button className="brown-button" onClick={reRoll}>
            Re-Roll
          </button>
          <button className="brown-button" onClick={closeModal}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RandomMapModal;
