import { MAP_IMAGE_BUCKET_URL } from "../constants/constants";
import store from "../store/store"; // Import your global store

const s3BucketUrl = MAP_IMAGE_BUCKET_URL;
const cacheName = "image-cache";

async function listObjects() {
  try {
    const response = await fetch(`${s3BucketUrl}?list-type=2`);

    if (response.status === 200) {
      const xmlString = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlString, "application/xml");

      const pngFiles = Array.from(xmlDoc.getElementsByTagName("Key"))
        .map((keyElement) => keyElement.textContent)
        .filter((key) => key.toLowerCase().endsWith(".png"));

      return pngFiles.map((item) => `${s3BucketUrl}/${item}`);
    } else {
      console.error("Non-200 status code received:", response.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
}

async function fetchAndCacheImages() {
  try {
    store.dispatch({ type: "SET_IMAGES_LOADING", payload: true }); // Dispatch loading state change

    const imageUrls = await listObjects();

    await Promise.all(
      imageUrls.map(async (imageUrl) => {
        try {
          const response = await fetch(imageUrl);
          const blob = await response.blob();

          // Cache the blob
          const cache = await caches.open(cacheName);
          const cacheKey = new Request(imageUrl);
          const cacheResponse = new Response(blob);
          await cache.put(cacheKey, cacheResponse);
        } catch (error) {
          console.error(`Error caching image: ${imageUrl}`, error);
        }
      })
    );
  } finally {
    store.dispatch({ type: "SET_IMAGES_LOADING", payload: false });
  }
}

export { fetchAndCacheImages };
