import React from "react";
import "./MapImage.scss";
import aosboxImage from "../../assets/aosbox.png";

const MapImage = ({ imageURL }) => {
  return (
    <div className="map-image-container">
      {imageURL ? (
        <img className="map-image" src={imageURL} alt="Map" />
      ) : (
        <img className="map-image" src={aosboxImage} alt="AOS Box" />
      )}
    </div>
  );
};

export default MapImage;
