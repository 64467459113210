// MapCompact.js
import React from "react";
import "./MapCompact.scss";
import { getBestAndPlaysMarkup } from "../../utils/helper-functions";

const MapCompact = ({ item, className }) => {
  const htmlContent = getBestAndPlaysMarkup(item);

  return (
    <div className={`map-container-compact ${className}`} key={item.id}>
      <div className="text-container-compact">
        <div className="item-header-compact">
          <h2>{item.Map}</h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
    </div>
  );
};

export default MapCompact;
