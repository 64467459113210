// reducer.js
import { createReducer } from "@reduxjs/toolkit";
import {
  fetchDataSuccess,
  fetchDataFailure,
  setFilterOptions,
  setFilterType,
  setSearchTerm,
  setSortOption,
  setModalVisibility,
  setUserCollectionData,
  setUserCollectionActive,
  setDisplayType,
  setImagesLoading,
} from "../actions/actions";

const initialState = {
  raw: null,
  filtered: null,
  sorted: null,
  filterOptions: ["all"],
  filterType: "plays",
  displayType: "normal",
  searchTerm: "",
  sortOption: "alphabetical",
  userCollectionData: null,
  userCollectionActive: false,
  error: null,
  modalVisible: false,
  imagesLoading: false,
};

const dataReducer = createReducer(initialState, (builder) => {
  builder
    // .addCase(fetchDataRequest, (state) => {
    //   // Handle the request action if needed
    // })
    .addCase(fetchDataSuccess, (state, action) => {
      state.raw = action.payload;
    })
    .addCase(fetchDataFailure, (state, action) => {
      state.error = action.payload;
    })
    .addCase(setFilterOptions, (state, action) => {
      state.filterOptions = action.payload;
    })
    .addCase(setFilterType, (state, action) => {
      state.filterType = action.payload;
    })
    .addCase(setSearchTerm, (state, action) => {
      state.searchTerm = action.payload;
    })
    .addCase(setSortOption, (state, action) => {
      state.sortOption = action.payload;
    })
    .addCase(setModalVisibility, (state, action) => {
      state.modalVisible = action.payload;
    })
    .addCase(setUserCollectionData, (state, action) => {
      state.userCollectionData = action.payload;
    })
    .addCase(setUserCollectionActive, (state, action) => {
      state.userCollectionActive = action.payload;
    })
    .addCase(setDisplayType, (state, action) => {
      state.displayType = action.payload;
    })
    .addCase(setImagesLoading, (state, action) => {
      state.imagesLoading = action.payload;
    });
});

export default dataReducer;
