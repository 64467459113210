import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilterOptions,
  setFilterType,
  setSearchTerm,
  setUserCollectionActive,
} from "../../actions/actions";
import { SEARCH_PLACEHOLDER } from "../../constants/constants";
import "./Filters.scss";

const Filters = () => {
  const dispatch = useDispatch();
  const filterOptions = useSelector((state) => state.data.filterOptions);
  const filterType = useSelector((state) => state.data.filterType);
  const searchTerm = useSelector((state) => state.data.searchTerm);
  const userCollectionData = useSelector(
    (state) => state.data.userCollectionData
  );
  const userCollectionActive = useSelector(
    (state) => state.data.userCollectionActive
  );

  const allFilterOptions = [
    "all",
    "1p",
    "2p",
    "3p",
    "4p",
    "5p",
    "6p",
    "7p",
    "8p",
  ];

  const getCheckedOption = (option) => {
    if (option.includes("p")) {
      return `p${option.slice(0, -1)}`;
    } else return "all";
  };

  const toggleFilterType = () => {
    dispatch(setFilterType(filterType === "best" ? "plays" : "best"));
  };

  //Special event listeners needed to distinguish PC and Mobile
  document.querySelectorAll(".checkbox-button").forEach((checkbox) => {
    checkbox.addEventListener("click", () => {
      checkbox.classList.toggle("checked");
    });

    checkbox.addEventListener("touchstart", () => {
      checkbox.classList.toggle("touched");
    });

    checkbox.addEventListener("mouseenter", () => {
      if (!checkbox.classList.contains("touched")) {
        checkbox.classList.add("hovered");
      }
    });

    checkbox.addEventListener("mouseleave", () => {
      checkbox.classList.remove("hovered");
    });
  });

  const handleFilterChange = (option) => {
    let updatedFilterOptions;

    if (option === "all") {
      updatedFilterOptions = [option];
    } else {
      updatedFilterOptions = filterOptions.includes(getCheckedOption(option))
        ? filterOptions.filter((item) => item !== getCheckedOption(option))
        : [...filterOptions, getCheckedOption(option)];

      if (
        updatedFilterOptions.length > 1 &&
        updatedFilterOptions.includes("all")
      ) {
        updatedFilterOptions = updatedFilterOptions.filter(
          (item) => item !== "all"
        );
      }
    }

    if (updatedFilterOptions.length === 0) {
      updatedFilterOptions = ["all"];
    }

    dispatch(setFilterOptions(updatedFilterOptions));
  };

  const toggleUserCollectionCheckbox = () => {
    dispatch(setUserCollectionActive(!userCollectionActive));
  };

  return (
    <div className="App-containers-blue">
      {/* User Toggle Switch */}
      {userCollectionData && (
        <div className="toggle-row">
          <div className="toggle-left">
            <label className="toggle-label">All Maps</label>
          </div>
          <div className="toggle-middle">
            <div
              className={`toggle-switch ${
                userCollectionActive ? "active" : ""
              }`}
              onClick={toggleUserCollectionCheckbox}
            >
              <div className="slider"></div>
            </div>
          </div>
          <div className="toggle-right">
            <label className="toggle-label">Owned</label>
          </div>
        </div>
      )}
      {/* Filter Type Toggle Switch */}
      <div className="toggle-row">
        <div className="toggle-left">
          <label className="toggle-label">Plays</label>
        </div>
        <div className="toggle-middle">
          <div
            className={`toggle-switch ${filterType === "best" ? "active" : ""}`}
            onClick={toggleFilterType}
          >
            <div className="slider"></div>
          </div>
        </div>
        <div className="toggle-right">
          <label className="toggle-label">Best At</label>
        </div>
      </div>

      {/* CHECKBOX MARKUP */}
      <div className="checkbox-container">
        {allFilterOptions.map((option) => (
          <label key={option} className="checkbox-label">
            <input
              type="checkbox"
              value={option}
              checked={filterOptions.includes(getCheckedOption(option))}
              onChange={() => handleFilterChange(option)}
              className="checkbox-input"
            />
            <div
              className={`checkbox-button ${
                filterOptions.includes(`${getCheckedOption(option)}`)
                  ? "checked"
                  : ""
              }`}
            >
              {option}
            </div>
          </label>
        ))}
      </div>

      {/* SEARCH BAR MARKUP */}
      <div className="search-bar">
        <input
          type="text"
          id="search"
          placeholder={SEARCH_PLACEHOLDER}
          value={searchTerm}
          onChange={(e) => dispatch(setSearchTerm(e.target.value))}
        />
      </div>
    </div>
  );
};

export default Filters;
