import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="App-footer">
      <a
        className="buyButton"
        target="_blank"
        href="https://www.buymeacoffee.com/spicyNoodles15"
        rel="noreferrer"
      >
        <img
          className="coffeeImage"
          src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg"
          alt="Buy me a coffee"
        />
        <span className="coffeeButtonText">Buy me a coffee</span>
      </a>
    </div>
  );
};

export default Footer;
