import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalVisibility, setDisplayType } from "../../actions/actions";
import BlueDice from "../../assets/dark-blue-dice.png";
import RandomMapModal from "../RandomModal/RandomModal";
import "./FilteredActionsBar.scss";

const FilteredActionsBar = () => {
  const dispatch = useDispatch();
  const modalVisible = useSelector((state) => state.data.modalVisible);
  const displayType = useSelector((state) => state.data.displayType);

  const openModal = () => {
    dispatch(setModalVisibility(true));
  };

  const closeModal = () => {
    dispatch(setModalVisibility(false));
  };

  const toggleDisplayType = () => {
    const newDisplayType = displayType === "normal" ? "compact" : "normal";
    dispatch(setDisplayType(newDisplayType));
  };

  return (
    <div className="App-containers-clear">
      <div className="image-container">
        {/* Filter Type Toggle Switch */}
        <div className="toggle-row-view">
          <div className="toggle-middle-view">
            <div
              className={`toggle-switch-view ${
                displayType === "compact" ? "active" : ""
              }`}
              onClick={toggleDisplayType}
            >
              <div className="slider-view"></div>
            </div>
          </div>
          <div className="toggle-right-view">
            <label className="toggle-label-view">Compact</label>
          </div>
        </div>
        <div className="random-map-text">Random:</div>
        <button
          onClick={openModal}
          style={{
            border: "none",
            background: "none",
            padding: 0,
            cursor: "pointer",
          }}
        >
          <img
            src={BlueDice}
            alt="Blue Dice for Random Map"
            style={{ width: 50, height: 38 }}
          />
        </button>
      </div>

      <RandomMapModal
        isOpen={modalVisible}
        closeModal={closeModal}
        appElement="#root"
      />
    </div>
  );
};

export default FilteredActionsBar;
