//Helper function to calculate the best player count for a given map
export const calculateBest = (map) => {
  let max = Number.MIN_SAFE_INTEGER;
  let maxLabels = [];
  let temp;

  for (let i = 1; i <= 8; i++) {
    const propertyName = `p${i}`;
    const propertyValue = Number(map[propertyName]);

    if (!isNaN(propertyValue)) {
      //has to at least play well at a player count
      if (propertyValue > 1) {
        if (propertyValue > max) {
          max = propertyValue;
          temp = propertyName.replace("p", "");
          maxLabels = [temp];
          maxLabels.push(", ");
        } else if (propertyValue === max) {
          temp = propertyName.replace("p", "");
          maxLabels.push(temp);
          maxLabels.push(", ");
        }
      }
    }
  }
  maxLabels.pop();
  //see if it's actually empty
  //If this is empty, the data is unavailable
  return maxLabels.length === 0 ? [] : maxLabels;
};

//Helper function to find all player counts for a given map
export const calculatePlays = (map) => {
  let playLabels = [];
  let temp;

  for (let i = 1; i <= 8; i++) {
    const propertyName = `p${i}`;
    const propertyValue = Number(map[propertyName]);

    if (!isNaN(propertyValue)) {
      if (propertyValue >= 3) {
        temp = propertyName.replace("p", "");
        playLabels.push(temp);
        playLabels.push(", ");
      }
    }
  }
  playLabels.pop();
  //see if it's actually empty
  //If this is empty, the data is unavailable
  return playLabels.length === 0 ? [] : playLabels;
};

//Helper function to construct links to BGG pages for each map
export const getBggLink = (map) => {
  const propertyName = `BGG IDs`;
  const linkNumbersCSV = map[propertyName];
  const baseURL = "https://boardgamegeek.com/boardgame/";
  let urlString = "";

  // Check if linkNumbersCSV is a non-empty string
  if (typeof linkNumbersCSV === "string" && linkNumbersCSV.trim() !== "") {
    // Split the CSV string into an array
    const linkNumbers = linkNumbersCSV.split(",");

    // Iterate over the array and generate HTML links
    linkNumbers.forEach((item, index) => {
      // Convert each item to a number
      const trimmedItem = item.trim();
      const numericItem = Number(trimmedItem);

      // Check if numericItem is a valid number
      if (!isNaN(numericItem)) {
        // Create an <a> element
        const linkElement = document.createElement("a");
        linkElement.href = baseURL + numericItem;
        linkElement.target = "_blank";
        linkElement.rel = "noopener  noreferrer";
        linkElement.textContent = numericItem;

        // Append the HTML string with a comma if not the last item
        urlString +=
          linkElement.outerHTML + (index < linkNumbers.length - 1 ? ", " : "");
      }
    });
  }

  return urlString;
};

// Helper function to construct a string that contains the best and plays
export const getBestAndPlaysMarkup = (map) => {
  const playsAtArray = calculatePlays(map);
  const bestAtArray = calculateBest(map);
  let bestAndPlaysMarkup = "";
  const playsAtArrayLength = playsAtArray.length;

  playsAtArray.forEach((item, index) => {
    const trimmedItem = item.trim();
    const numericItem = Number(trimmedItem);

    // Check if numericItem is a valid number
    if (!isNaN(numericItem)) {
      const isBest = bestAtArray.includes(item);
      const className = isBest ? "big-text" : "small-text";

      // Check if the current item is the last one
      if (index === playsAtArrayLength - 1) {
        // Don't add final comma for the last element
        bestAndPlaysMarkup += `<div class="player-count-text"><div class="${className}">${item}</div></div>`;
      } else {
        bestAndPlaysMarkup += `<div class="player-count-text"><div class="${className}">${item}</div>,</div>`;
      }
    }
  });

  if (bestAndPlaysMarkup.length === 0) {
    bestAndPlaysMarkup = "Unknown";
  }

  // Return a single div with all numbers in a row
  return `<div class="player-count-text-row">${bestAndPlaysMarkup}</div>`;
};
