import React, { useState } from "react";
import "./SearchUser.scss";
import { useDispatch } from "react-redux";
import { fetchUserCollection } from "../../services/bgg_api";
import {
  setUserCollectionData,
  setUserCollectionActive,
} from "../../actions/actions";

const UserSearch = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const getUserCollection = async () => {
    if (validateUserInput(username.trim())) {
      setLoading(true);
      setSuccessMessage("");

      try {
        const response = await fetchUserCollection(username.trim());

        if (response) {
          // Check if the response contains valid data
          dispatch(setUserCollectionData(response));
          dispatch(setUserCollectionActive(true));
          setSuccessMessage(`${username}'s Collection Found!`);
        } else {
          // The response is truthy but does not contain valid data
          dispatch(setUserCollectionData(null));
          dispatch(setUserCollectionActive(false));
          setSuccessMessage("");
          setError("Failed to fetch valid data");
        }
      } catch (error) {
        // Handle other errors
        dispatch(setUserCollectionData(null));
        dispatch(setUserCollectionActive(false));
        setError("Failed to fetch data");
        setSuccessMessage("");
      } finally {
        setLoading(false);
      }
    }
  };

  const validateUserInput = (username) => {
    let usernameValid = true;

    if (!username.trim()) {
      setError("Please enter a valid username");
      usernameValid = false;
    } else {
      setError(null);
    }

    return usernameValid;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      getUserCollection();
    }
  };

  return (
    <div className="App-containers-collection">
      {/* Search Bar */}
      <div className="search-container-content">
        <div className="search-bar-user">
          <input
            type="text"
            placeholder="BGG Username..."
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button className="blue-button" onClick={getUserCollection}>
            Get Collection
          </button>
        </div>
      </div>

      {/* Get Collection Button */}
      <div style={{ display: "flex", alignItems: "center" }}>
        {loading && <p className="loading-message">Loading...</p>}
        {!loading && error && <p className="error-message">{error}</p>}
        {!loading && successMessage && (
          <p className="success-message">{successMessage}</p>
        )}
      </div>
    </div>
  );
};

export default UserSearch;
