// actions.js
import { createAction } from "@reduxjs/toolkit";

export const fetchDataRequest = createAction("FETCH_DATA_REQUEST");
export const fetchDataSuccess = createAction("FETCH_DATA_SUCCESS");
export const fetchDataFailure = createAction("FETCH_DATA_FAILURE");
export const setFilterOptions = createAction(
  "SET_FILTER_OPTIONS",
  (options) => ({
    payload: Array.isArray(options) ? options : [options],
  })
);
export const setFilterType = createAction("SET_FILTER_TYPE");
export const setDisplayType = createAction("SET_DISPLAY_TYPE");
export const setSearchTerm = createAction("SET_SEARCH_TERM");
export const setSortOption = createAction("SET_SORT_OPTION");
export const setModalVisibility = createAction("SET_MODAL_VISIBILITY");
export const setUserCollectionData = createAction(
  "SET_USER_COLLECTION_DATA",
  (userCollectionData) => ({
    payload: userCollectionData,
  })
);
export const setUserCollectionActive = createAction(
  "SET_USER_COLLECTION_ACTIVE"
);

export const setImagesLoading = createAction(
  "SET_IMAGES_LOADING",
  (isLoading) => ({
    payload: isLoading,
  })
);
