// MapTree.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDataRequest,
  fetchDataSuccess,
  fetchDataFailure,
} from "../../actions/actions";
import { fetchData } from "../../services/api";
import Map from "../Map/Map";
import MapCompact from "../MapCompact/MapCompact";
import FilteredActionsBar from "../FilteredActionsBar/FilteredActionsBar";
import { SEARCH_PLACEHOLDER } from "../../constants/constants";
import {
  calculateBest,
  calculatePlays,
  getBggLink,
} from "../../utils/helper-functions";

const MapTree = () => {
  //STATE VARIABLES
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.raw);
  const filterOptions = useSelector((state) => state.data.filterOptions);
  const filterType = useSelector((state) => state.data.filterType);
  const searchTerm = useSelector((state) => state.data.searchTerm);
  const sortOption = useSelector((state) => state.data.sortOption);
  const displayType = useSelector((state) => state.data.displayType);
  const userCollectionActive = useSelector(
    (state) => state.data.userCollectionActive
  );
  const userCollectionData = useSelector(
    (state) => state.data.userCollectionData
  );
  const error = useSelector((state) => state.data.error);
  const [loading, setLoading] = useState(true);

  // Fetch data from dynamoDB
  useEffect(() => {
    const fetchDataFromApi = async () => {
      dispatch(fetchDataRequest());

      try {
        const result = await fetchData();
        dispatch(fetchDataSuccess(result));
        setLoading(false);
      } catch (err) {
        dispatch(fetchDataFailure(err.message));
        setLoading(false);
      }
    };

    fetchDataFromApi();
  }, [dispatch]);

  useEffect(() => {}, [displayType]);

  //Method to see if a given map meets filter criteria
  const shouldIncludeItem = (item) => {
    let searchCheck;
    if (searchTerm.toLowerCase() !== { SEARCH_PLACEHOLDER }) {
      searchCheck =
        item.Map.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.Designer.toLowerCase().includes(searchTerm.toLowerCase());
    }

    if (filterOptions.includes("all")) {
      return true && searchCheck;
    }

    if (filterType === "plays") {
      const shouldInclude = filterOptions.some(
        (option) => item[`${option}`] >= 3
      );
      return shouldInclude && searchCheck;
    } else if (filterType === "best") {
      const bestAt = calculateBest(item);
      const shouldInclude = filterOptions.some((option) =>
        bestAt.includes(String(option.slice(1)))
      );
      return shouldInclude && searchCheck;
    }
  };

  // Method that retrieves the filtered data
  const getFilteredData = () => {
    let filteredData;
    if (userCollectionActive && userCollectionData) {
      filteredData = filterUserData();
    } else if (data) {
      filteredData = data.filter(shouldIncludeItem);
    } else {
      filteredData = [];
    }
    return filteredData.filter(shouldIncludeItem);
  };

  const filterUserData = () => {
    // Check if userCollectionData and userCollectionData.items exist
    if (!userCollectionData || !userCollectionData.items) {
      return [];
    }

    // Ensure that items is an array
    const userCollectionItems = Array.isArray(userCollectionData.items.item)
      ? userCollectionData.items.item
      : userCollectionData.items.item
      ? [userCollectionData.items.item] // wrap in an array if it's a single item
      : [];

    // Extracting object IDs from userCollectionData
    const userCollectionIDs = userCollectionItems.map((item) => {
      return item._attributes && item._attributes.objectid
        ? String(item._attributes.objectid)
        : null;
    });

    // Filter out null values (in case objectid is missing in some items)
    const validUserCollectionIDs = userCollectionIDs.filter(
      (id) => id !== null
    );

    // Then match the object IDs with data.id
    return data.filter((item) => {
      const bggIDs = item["BGG IDs"];

      // Check if bggIDs is a string with comma-separated values
      if (typeof bggIDs === "string") {
        const bggIDsArray = bggIDs.split(", ").map((id) => id.trim());

        // Check if any value in bggIDsArray is included in validUserCollectionIDs
        return bggIDsArray.some((bggID) =>
          validUserCollectionIDs.includes(bggID)
        );
      }

      // If bggIDs is not a string, check if it's included in validUserCollectionIDs
      return validUserCollectionIDs.includes(String(bggIDs));
    });
  };

  // Method that retrieves the sorted data
  const getSortedData = () => {
    const filteredData = getFilteredData();

    switch (sortOption) {
      case "alphabetical":
        return [...filteredData].sort((a, b) => a.Map.localeCompare(b.Map));
      default:
        return filteredData;
    }
  };

  // Helper function to calculate number of filtered maps
  const getFilteredLength = () => {
    return getFilteredData().length;
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <h1>
            {userCollectionActive
              ? `${getFilteredLength()} User Owned Maps Found`
              : `${getFilteredLength()} Maps Found`}
          </h1>
          <FilteredActionsBar />
          {/* Conditionally render Map or MapCompact based on displayType */}
          {getSortedData().map((item) =>
            // Use a ternary operator to conditionally render Map or MapCompact
            displayType === "compact" ? (
              <MapCompact
                key={item.id}
                item={item}
                calculatePlays={calculatePlays}
                calculateBest={calculateBest}
                getBggLink={getBggLink}
                className="App-containers-compact"
              />
            ) : (
              <Map
                key={item.id}
                item={item}
                calculatePlays={calculatePlays}
                calculateBest={calculateBest}
                getBggLink={getBggLink}
                className="App-containers-green"
              />
            )
          )}
        </div>
      )}
      {error && <p>Error: {error}</p>}
    </div>
  );
};

export default MapTree;
