import axios from "axios";
import { API_BASE_URL } from "../constants/constants";

const api = axios.create({
  baseURL: API_BASE_URL,
});

// NEED TO HAVE A '/' at the end of the endpoint in axios!!!
export const fetchData = async () => {
  try {
    const response = await api.post(
      "/maps/",
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};
